import { Button, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CreateTechnologyActions } from '../../../../store/createTechnologyForm'

export const EmptyState: FC<{ message: string; icon: any }> = ({ message, icon }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleBack = (path: string) => {
    dispatch(CreateTechnologyActions.resetState())
    navigate(path)
  }

  return (
    <Stack mt={4} alignItems="center" justifyContent="center" gap={2} maxWidth={500}>
      <img src={icon} alt="empty-icon" width={200} />
      <Typography color="secondary.light" fontSize="1rem" fontWeight={500} textAlign="center">
        {message}
      </Typography>
      <Button
        variant="contained"
        sx={{ borderRadius: '7px', textTransform: 'capitalize' }}
        onClick={() => handleBack('/addTechnology')}
      >
        {' '}
        Adjust Tech
      </Button>
    </Stack>
  )
}
