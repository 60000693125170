import { createSelector } from '@reduxjs/toolkit'
import { ICompany, IOrganization, ISubscription } from '../../types'
import decodeToken from '../../utils/jwtUtil'
import { RootState } from '../store'

const auth = (state: RootState) => state.auth
const userId = (state: RootState) => state.auth.data._id || ''
const userData = (state: RootState) => state.auth.data || {}
const isLoading = (state: RootState) => state.auth.loading
const isSuccess = (state: RootState) => state.auth.success
const error = (state: RootState) => state.auth.error
const authToken = (state: RootState) => state.auth.data.token || ''

const hasToken = createSelector(authToken, (_token) => {
  if (!_token) {
    return false
  }

  const decodedToken = decodeToken(_token)
  if (!decodedToken || !decodedToken.exp) {
    return false
  }

  return true
})

const userOrg = createSelector(userData, (user) => {
  return user.organizations
})

const userCompany = createSelector(userData, (user) => {
  return user.company || ({} as ICompany)
})

const trialDates = createSelector(userData, (user) => {
  if (!user || !user.organizations) {
    return ''
  }

  const { organizations = {} as IOrganization } = user
  const { subscriptions } = organizations || { subscriptions: [] as ISubscription[] }

  const trialSubscription = subscriptions.find(({ status }: ISubscription) => status === 'trialing')

  return trialSubscription || ''
})

const isSubscriptionValid = createSelector(userData, (user) => {
  const { organizations = {} as IOrganization } = user
  const { subscriptions } = organizations || { subscriptions: [] as ISubscription[] }

  if (subscriptions && subscriptions.length) {
    return subscriptions.some(
      ({ status }: ISubscription) => status === 'trialing' || status === 'active',
    )
  }
  return false
})

const userRole = createSelector(userData, (user) => {
  const { organizations = {} as IOrganization } = user || {}

  if (!organizations) {
    return 'INVALID_ORG'
  }

  if (organizations.isAdmin) {
    return 'ORG_ADMIN'
  }

  return 'ORG_MEMBER'
})

const userCompanyRole = createSelector(userData, (user) => {
  const { company = {} as ICompany } = user || {}

  if (!company) {
    return 'INVALID_COMPANY'
  }

  if (company.isAdmin) {
    return 'COMPANY_ADMIN'
  }

  return 'COMPANY_MEMBER'
})

export const AuthSelector = {
  userId,
  userData,
  hasToken,
  isLoading,
  isSuccess,
  authToken,
  userRole,
  userOrg,
  userCompany,
  isSubscriptionValid,
  auth,
  trialDates,
  error,
  userCompanyRole,
}
