import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import decodeToken from '../utils/jwtUtil'
import { AgenciesReducer } from './agencies'
import { AuthReducer } from './auth'
import { EmailVerificationReducer } from './auth/resendEmailVerification.slice'
import { CompanyReducer } from './company'
import { companyPreferencesReducer } from './companyPreferences'
import { CreateTechnologyReducer } from './createTechnologyForm'
import { CurrentUserReducer } from './currentUser'
import { FormsReducer } from './forms'
import { GrantMatchReducer } from './grantMatcher'
import { GrantsReducer } from './grants'
import { metaReducer } from './meta/meta.slice'
import { AuthMiddleware } from './middlewares'
import { PublicReducer } from './public'
import { RegistrationProgressReducer } from './registrationProgress'
import { SnackbarReducer } from './snackbar'
import { SolicitationsReducer } from './solicitations'
import { SubscriptionReducer } from './subscription'
import { TechnologiesReducer } from './technologies'
import { TechnologyReducer } from './technology'
import { TechGrantsReducer } from './technology/grants.slice'
import { MetricsReducer } from './TechnologyMetrics/metrics.slice'

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  grants: GrantsReducer,
  forms: FormsReducer,
  grantMatch: GrantMatchReducer,
  technologies: TechnologiesReducer,
  technology: combineReducers({ tech: TechnologyReducer, grants: TechGrantsReducer }),
  auth: AuthReducer,
  currentUser: CurrentUserReducer,
  agencies: AgenciesReducer,
  createTechnologyForm: CreateTechnologyReducer,
  subscription: SubscriptionReducer,
  company: CompanyReducer,
  registrationProgress: RegistrationProgressReducer,
  meta: metaReducer,
  snackbar: SnackbarReducer,
  emailVerify: EmailVerificationReducer,
  companyPreferences: companyPreferencesReducer,
  public: PublicReducer,
  metrics: MetricsReducer,
  solicitations: SolicitationsReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)
const token: string = localStorage.getItem('authToken') || ''
let payload: any
if (token) {
  payload = decodeToken(token)
}

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().prepend(AuthMiddleware.middleware)
  },
  preloadedState: { auth: { data: { ...payload, token } } },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
