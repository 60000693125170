import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const HappyFace: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M34.142 6.79368C30.3645 3.01618 25.342 0.935791 19.9998 0.935791C14.6577 0.935791 9.63531 3.01618 5.85781 6.79368C2.08031 10.5712 0 15.5936 0 20.9358C0 26.278 2.08031 31.3004 5.85781 35.0779C9.63531 38.8554 14.6577 40.9358 19.9998 40.9358C25.342 40.9358 30.3645 38.8554 34.142 35.0779C37.9195 31.3004 39.9998 26.278 39.9998 20.9358C39.9998 15.5936 37.9195 10.5712 34.142 6.79368ZM19.9998 38.592C10.2642 38.592 2.34375 30.6715 2.34375 20.9358C2.34375 11.2001 10.2642 3.27954 19.9998 3.27954C29.7355 3.27954 37.6561 11.2001 37.6561 20.9358C37.6561 30.6715 29.7355 38.592 19.9998 38.592Z"
        fill="black"
      />
      <path
        d="M12.4687 12.2327C9.74617 12.2327 7.53125 14.4477 7.53125 17.1702C7.53125 17.8174 8.05594 18.3421 8.70312 18.3421C9.35031 18.3421 9.875 17.8174 9.875 17.1702C9.875 15.74 11.0385 14.5764 12.4687 14.5764C13.8988 14.5764 15.0623 15.74 15.0623 17.1702C15.0623 17.8174 15.587 18.3421 16.2342 18.3421C16.8814 18.3421 17.4061 17.8174 17.4061 17.1702C17.4061 14.4477 15.1912 12.2327 12.4687 12.2327Z"
        fill="black"
      />
      <path
        d="M27.5312 12.2327C24.8087 12.2327 22.5938 14.4477 22.5938 17.1702C22.5938 17.8174 23.1184 18.3421 23.7656 18.3421C24.4128 18.3421 24.9375 17.8174 24.9375 17.1702C24.9375 15.74 26.101 14.5764 27.5312 14.5764C28.9613 14.5764 30.1248 15.74 30.1248 17.1702C30.1248 17.8174 30.6495 18.3421 31.2967 18.3421C31.9439 18.3421 32.4686 17.8174 32.4686 17.1702C32.4686 14.4477 30.2537 12.2327 27.5312 12.2327Z"
        fill="black"
      />
      <path
        d="M31.0435 23.5965C30.4334 23.3808 29.7638 23.7006 29.5481 24.3108C28.1211 28.3482 24.2841 31.0608 20 31.0608C15.7159 31.0608 11.8788 28.3482 10.4519 24.3108C10.2363 23.7006 9.56657 23.3808 8.95649 23.5965C8.34626 23.8121 8.02642 24.4816 8.24212 25.0918C9.99946 30.0639 14.7246 33.4045 20 33.4045C25.2754 33.4045 30.0005 30.0639 31.7579 25.0918C31.9736 24.4816 31.6538 23.8121 31.0435 23.5965Z"
        fill="black"
      />
    </SvgIcon>
  )
}
