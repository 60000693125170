import { Box, Paper, Step, StepLabel, Stepper, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch } from '../../../../store'
import { AuthSelector } from '../../../../store/auth'
import { CompanyActions, CompanySelectors } from '../../../../store/company'
import { RegistrationProgressActions } from '../../../../store/registrationProgress'
import { StripeCheckout } from '../../../Subscription'
import { CompanyInfo } from './CompanyInfo'
import { InviteTeamMember } from './InviteTeamMember'
import { PersonalInfo } from './PersonalInfo'

export const RegistrationSteps = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const isXs = useMediaQuery(theme.breakpoints.down('sm'))

  const userRole = useSelector(AuthSelector.userRole)
  const currentStep = useSelector(CompanySelectors.stepCount)
  const stageStatuses = useSelector(CompanySelectors.getStageStatuses)

  useEffect(() => {
    dispatch(RegistrationProgressActions.fetchRegistrationProgressAsync())
  }, [])

  const [steps, setSteps] = useState([
    'Personal information',
    'Choose subscription',
    'Company information',
    'Invite team member',
  ])

  const handleNext = () => {
    if (userRole === 'ORG_MEMBER') {
      handleNavigate()
    } else {
      dispatch(CompanyActions.incrementStage())
    }
  }

  const handleNavigate = () => {
    navigate('/completed', { replace: true })
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <PersonalInfo />
      case 1:
        return <StripeCheckout />
      case 2:
        return <CompanyInfo handleNext={handleNext} />
      case 3:
        return <InviteTeamMember handleNext={handleNavigate} />
      default:
        return null
    }
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      {userRole !== 'ORG_MEMBER' && (
        <Paper
          elevation={0}
          sx={{
            margin: 'auto',
            display: 'flex',
            borderRadius: 2,
            alignItems: 'center',
            border: '1px solid #E2E8F0',
            width: { xs: '100%', md: '70%' },
          }}
        >
          <Stepper
            activeStep={currentStep}
            orientation="horizontal"
            sx={{ width: '100%', p: 2 }}
            alternativeLabel={isXs ? true : false}
          >
            {steps.map((label, index) => {
              return (
                <Step key={label} completed={stageStatuses[index]}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Paper>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4, width: '100%' }}>
        {currentStep === 1 ? (
          getStepContent(currentStep)
        ) : (
          <Paper
            elevation={0}
            sx={{
              p: 2,
              display: 'flex',
              borderRadius: '8px',
              flexDirection: 'row',
              border: '1px solid #E2E8F0',
              maxWidth: '500px',
              width: '100%',
            }}
          >
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: 3, width: '100%' }}>
              {getStepContent(currentStep)}
            </Box>
          </Paper>
        )}
      </Box>
    </Box>
  )
}
