import { createSelector } from '@reduxjs/toolkit'
import { MetaSelectors } from '../meta/meta.selector'
import type { RootState } from '../store'

const userDetail = (state: RootState) => state.currentUser.data.user
const isLoading = (state: RootState) => state.currentUser.loading
const userAvatar = (state: RootState) => state.currentUser.data.user.avatar || ''
const userEmail = (state: RootState) => state.currentUser.data.user.email || ''
const companyDetail = (state: RootState) => state.currentUser.data.company || {}
const companyMembers = (state: RootState) => state.currentUser.data.companyMembers || []
const error = (state: RootState) => state.currentUser.error

const fullName = createSelector(userDetail, (user) =>
  `${user.firstName || ''} ${user.lastName || ''}`.trim(),
)

const userInitials = createSelector(userDetail, (user) => {
  const firstInitial = user.firstName ? user.firstName[0] : ''
  const lastInitial = user.lastName ? user.lastName[0] : ''

  return firstInitial + lastInitial
})

const makeCompanyMembers = createSelector(
  companyMembers,
  userEmail,
  (members, currentUserEmail) => {
    const filteredMembers = members && members.filter((member) => member.email !== currentUserEmail)

    return filteredMembers
  },
)

const makeCompanyAddress = createSelector(
  companyDetail,
  MetaSelectors.getUsaStatesNameAbbreviation,
  (company, states) => {
    if (states && company && company.address) {
      const { address1 = '', city = '', zip = '', state: stateAbbr = '' } = company.address
      const [state] = states.filter(({ abbreviation }) => abbreviation === stateAbbr)
      const stateName = state ? state.name : ''
      const addressParts = [address1, city, stateName, zip]

      const populatedAddress = addressParts.filter(Boolean).join(', ')

      return populatedAddress || ' '
    }
  },
)

export const CurrentUserSelector = {
  userDetail,
  isLoading,
  userAvatar,
  userEmail,
  fullName,
  userInitials,
  companyDetail,
  makeCompanyMembers,
  makeCompanyAddress,
  error,
}
