import { Box, Stack, Typography } from '@mui/material'
import { FC } from 'react'

export const GrantDescription: FC<{ description: string | object }> = ({ description }) => {
  return (
    <Box my={3}>
      {typeof description === 'object' ? (
        <>
          {Object.entries(description).map(([key, value]) => (
            <Stack mb={2} gap={0.5} key={key}>
              <Typography fontSize="1rem" fontWeight={700} color="secondary.main">
                {key}
              </Typography>
              <Typography fontSize="0.875rem" color="secondary.light" whiteSpace="break-spaces">
                {value}
              </Typography>
            </Stack>
          ))}
        </>
      ) : (
        <Typography
          component="div"
          color="secondary.light"
          sx={{
            width: '100%',
            whiteSpace: 'break-spaces',
          }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </Box>
  )
}
