import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const LighBulbColored: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5236_1629)">
        <path
          d="M30.0001 36.7644C30.6001 34.7644 31.8001 32.9644 33.4001 31.5644C36.4001 29.0643 38.3001 25.2644 38.3001 21.0644C38.3001 13.2644 31.8002 6.86434 23.8001 7.26437C16.7001 7.56437 10.9 13.5644 10.7001 20.6643C10.6 25.0644 12.5001 28.9644 15.6001 31.5644C17.3001 32.9643 18.5001 34.7643 19.1001 36.7644H30.0001Z"
          fill="#FFD15C"
        />
        <path
          d="M23.2001 36.9647H24.1001L20.1 22.0647C20.2001 22.0647 20.3 22.0647 20.4 22.0647C20.9 22.0647 21.4001 21.8647 21.8 21.4647C22 21.2647 22.2 21.1647 22.5 21.1647C22.8 21.1647 23 21.2647 23.2001 21.4647C23.9001 22.2647 25.1001 22.2647 25.8 21.4647C26 21.2647 26.2001 21.1647 26.5001 21.1647C26.7 21.1647 27 21.2647 27.2001 21.4647C27.6001 21.8647 28.0001 22.0647 28.6001 22.0647C28.7001 22.0647 28.8 22.0647 28.9001 22.0647L25.0001 36.9647H25.9001L30 21.3647C30 21.1647 30 20.9647 29.8001 20.8647C29.6001 20.7647 29.4 20.8647 29.3001 20.9647C29.1001 21.1647 28.9001 21.2647 28.7001 21.2647C28.4001 21.2647 28.2001 21.1647 27.9001 20.9647C27.5001 20.5647 27.1002 20.3647 26.6001 20.3647C26.1 20.3647 25.7001 20.5647 25.3001 20.9647C24.9 21.3648 24.3 21.3648 23.9001 20.9647C23.6001 20.5647 23.1001 20.3647 22.6001 20.3647C22.1001 20.3647 21.6 20.5647 21.3 20.9647C21.1001 21.1647 20.8001 21.2647 20.5001 21.2647C20.3001 21.2647 20.0001 21.1647 19.9001 20.9647C19.8 20.8647 19.6001 20.7648 19.4001 20.8647C19.2001 20.9647 19.1001 21.1647 19.2001 21.3647L23.2001 36.9647Z"
          fill="white"
        />
        <path
          d="M21.4001 45.9648C21.9002 47.1648 23.1002 48.0648 24.5002 48.0648C25.9001 48.0648 27.1001 47.1648 27.6002 45.9648H21.4001Z"
          fill="#344A5E"
        />
        <path
          d="M28.2001 46.0646H20.9001C19.9001 46.0646 19.1001 45.2646 19.1001 44.2646V36.6646H30.0001V44.2646C30.0001 45.2645 29.2001 46.0646 28.2001 46.0646Z"
          fill="#344A5E"
        />
        <path
          d="M29.9 40.4646H19.1C18.5 40.4646 18 39.9646 18 39.3646C18 38.7646 18.5 38.2646 19.1 38.2646H29.9C30.5 38.2646 30.9999 38.7646 30.9999 39.3646C30.9999 39.9646 30.5 40.4646 29.9 40.4646Z"
          fill="#415A6B"
        />
        <path
          d="M29.9 43.9646H19.1C18.5 43.9646 18 43.4646 18 42.8646C18 42.2646 18.5 41.7646 19.1 41.7646H29.9C30.5 41.7646 30.9999 42.2646 30.9999 42.8646C30.9999 43.4645 30.5 43.9646 29.9 43.9646Z"
          fill="#415A6B"
        />
        <path
          d="M24.5 0.0644531C24.0001 0.0644531 23.5 0.464484 23.5 1.06448V4.46452C23.5 4.96448 23.9 5.46455 24.5 5.46455C25.1 5.46455 25.5001 5.06452 25.5001 4.46452V1.06448C25.5001 0.464484 25 0.0644531 24.5 0.0644531Z"
          fill="#FFD15C"
        />
        <path
          d="M11.1001 6.16428C10.7001 5.76425 10.1001 5.76425 9.70013 6.16428C9.3002 6.56431 9.3001 7.16431 9.70013 7.56425L12.1001 9.96425C12.5002 10.3643 13.1002 10.3643 13.5001 9.96425C13.9 9.56422 13.9001 8.96422 13.5001 8.56428L11.1001 6.16428Z"
          fill="#FFD15C"
        />
        <path
          d="M7.99992 19.9644H4.59988C4.09992 19.9644 3.59985 20.3644 3.59985 20.9644C3.59985 21.4644 3.99988 21.9644 4.59988 21.9644H7.99992C8.49988 21.9644 8.99995 21.5644 8.99995 20.9644C8.99995 20.4643 8.49988 19.9644 7.99992 19.9644Z"
          fill="#FFD15C"
        />
        <path
          d="M12.0999 31.9641L9.69993 34.3641C9.29989 34.7641 9.29989 35.3641 9.69993 35.764C10.1 36.164 10.7 36.1641 11.0999 35.764L13.4999 33.364C13.8999 32.964 13.8999 32.364 13.4999 31.9641C13.0999 31.5641 12.5 31.564 12.0999 31.9641Z"
          fill="#FFD15C"
        />
        <path
          d="M36.9 31.9641C36.5 31.5641 35.9 31.5641 35.5 31.9641C35.0999 32.3641 35.0999 32.9641 35.5 33.3641L37.9 35.7641C38.3 36.1641 38.9 36.1641 39.2999 35.7641C39.6999 35.364 39.7 34.764 39.2999 34.3641L36.9 31.9641Z"
          fill="#FFD15C"
        />
        <path
          d="M44.4001 19.9644H41C40.5001 19.9644 40 20.3644 40 20.9644C40 21.4644 40.4 21.9644 41 21.9644H44.4001C44.9 21.9644 45.4001 21.5644 45.4001 20.9644C45.4 20.4643 45.0001 19.9644 44.4001 19.9644Z"
          fill="#FFD15C"
        />
        <path
          d="M37.9 6.16425L35.5 8.56425C35.0999 8.96428 35.0999 9.56428 35.5 9.96421C35.9 10.3642 36.5 10.3642 36.8999 9.96421L39.2999 7.56421C39.7 7.16418 39.7 6.56418 39.2999 6.16425C38.8999 5.76431 38.3 5.76421 37.9 6.16425Z"
          fill="#FFD15C"
        />
      </g>
      <defs>
        <clipPath id="clip0_5236_1629">
          <rect width="48" height="48" fill="white" transform="translate(0.5 0.0644531)" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
