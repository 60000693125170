import { Add } from '@mui/icons-material'
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material'
import React, { MouseEventHandler, useState } from 'react'
import { useSelector } from 'react-redux'
import { MetaSelectors } from '../../../store/meta/meta.selector'

interface QuestionAnswer {
  question: string
  answer: string
}

export const Faq: React.FC = () => {
  const theme = useTheme()
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null)
  const questionAnswers = useSelector(MetaSelectors.questionsAnswers)

  const handleIconClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    const index = Number(event.currentTarget.getAttribute('data-index'))
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: { xs: 'column', md: 'row' },
          mt: 2,
          backgroundColor: '#EFF3F7',
          borderRadius: '4px',
          p: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            p: { xs: 2, md: 8 },
            alignItems: 'flex-start',
            flex: 1,
          }}
          gap={3}
        >
          <Typography variant="h5" fontWeight={theme.typography.fontWeightBold} textAlign="left">
            Have questions? We've got answers.
          </Typography>
          <Box sx={{ marginBottom: 1 }}>
            <Typography variant="body1" sx={{ textAlign: 'left', mt: 2 }}>
              Check out our list of questions people often ask to find what you're looking for. If
              you don't see your question, just ask our support team for help.
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', mt: 2 }}>
              Message our team with any further questions at info@catalyzingconcepts.com.
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', mt: 2 }}>
              Want to book a demo or a meeting with our team? Click below to schedule a time.
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={() => window.open('https://calendly.com/ben-bick/grantmatch-demo', '_blank')}
            sx={{
              textTransform: 'none',
              borderRadius: '20px',
            }}
          >
            Book a meeting
          </Button>
        </Box>

        <Box sx={{ p: { xs: 1, md: 2 }, flex: 1, textAlign: 'left', pr: 4, mt: { xs: 2, md: 5 } }}>
          {questionAnswers.map((questionAnswer, index) => (
            <Box
              key={questionAnswer.question}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 4,
                borderBottom: '1px solid #ddd',
                p: 2,
                textAlign: 'left',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  paddingLeft: '10px',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: theme.typography.fontWeightBold,
                    flexGrow: 1,
                    textAlign: 'left',
                  }}
                >
                  {questionAnswer.question}
                </Typography>
                <IconButton
                  onClick={handleIconClick}
                  aria-expanded={expandedIndex === index}
                  aria-label="show answer"
                  data-index={index}
                  sx={{ ml: 'auto' }}
                >
                  <Add />
                </IconButton>
              </Box>
              {expandedIndex === index && (
                <Typography variant="body2" sx={{ mt: 2, ml: 1, textAlign: 'left' }}>
                  {questionAnswer.answer}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
