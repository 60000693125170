import { Check } from '@mui/icons-material'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { FC } from 'react'

interface ISubscriptionPlanProps {
  name: string
  amount: number
  interval: string
}

const benefits = [
  'Unlimited Users',
  'User Roles',
  'Unlimited technologies',
  'Technology specific notifications',
  'Quick search with unlimited results',
  '10 hours of free consultation',
]

export const SubscriptionPlan: FC<ISubscriptionPlanProps> = ({ name, amount, interval }) => {
  const theme = useTheme()

  return (
    <Box>
      <Typography
        color="secondary"
        fontWeight={theme.typography.fontWeightMedium}
        fontSize="1.5rem"
      >
        Subscription Plan
      </Typography>

      <Stack p={2} my={1}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography
            color="secondary"
            fontWeight={theme.typography.fontWeightBold}
            fontSize="1rem"
          >
            {name}
          </Typography>
          <Typography
            color="secondary"
            fontWeight={theme.typography.fontWeightBold}
            fontSize="1rem"
          >
            {`$${amount}/${interval}`}
          </Typography>
        </Stack>
        {benefits.map((benefit, index) => (
          <Stack key={index} direction="row" gap={1.2} sx={{ mb: 2 }}>
            <Box
              sx={{
                width: 20,
                height: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px',
                background: theme.palette.primary.main,
              }}
            >
              <Check sx={{ fontSize: '0.875rem', color: 'white' }} />
            </Box>
            <Typography
              fontWeight={theme.typography.fontWeightMedium}
              fontSize="0.875rem"
              color="secondary.light"
            >
              {benefit}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  )
}
