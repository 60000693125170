import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const getFormValues = createSelector(
  (state: RootState) => state.forms,
  (state: RootState, id: string) => id,
  (forms, id) => forms[id] || {},
)
export const FormsSelector = {
  getFormValues,
}
