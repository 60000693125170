import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { CustomSnackbar } from './components/CustomSnackbar'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
import { ThemeProvider } from './theme'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const IndexApp = () => (
  <React.StrictMode>
    <ThemeProvider>
      <Provider store={store}>
        <SnackbarProvider maxSnack={3} preventDuplicate={true}>
          <BrowserRouter basename="/">
            <CustomSnackbar />
            <App />
          </BrowserRouter>
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
)

root.render(<IndexApp />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
