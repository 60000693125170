import { FC } from 'react'
import { CustomModal } from '../../../../../../components/customModal'
import { AddToTechnologyForm } from './AddToTechnologyForm'

interface AddToTechnologyModalProps {
  open: boolean
  setOpen: Function
  grantId: string
}

export const AddToTechnologyModal: FC<AddToTechnologyModalProps> = ({ open, setOpen, grantId }) => {
  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      title="Add to technology"
      closeButtonAction={() => setOpen(false)}
    >
      <AddToTechnologyForm showModal={setOpen} grantId={grantId} />
    </CustomModal>
  )
}
