import { Box, Button, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { SendEmailIcon } from '../../../../components/icons'

export const EmailVerification = () => {
  const navigate = useNavigate()

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="75vh">
      <Box maxWidth="450px" bgcolor="#FFFFFF">
        <Paper
          elevation={0}
          sx={{
            p: 6,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '450px',
            alignItems: 'center',
          }}
        >
          <SendEmailIcon color="success" sx={{ fontSize: 100 }} />

          <Typography component="h1" variant="h5" sx={{ mt: 2, mb: 1 }}>
            Email verification
          </Typography>
          <Typography variant="body2" sx={{ mt: 4, textAlign: 'center' }}>
            Please verify your email address to start using our service. Check your email inbox for
            a message from us and follow the instructions to complete the verification process.
          </Typography>

          <Button
            sx={{ my: 2, fontSize: '0.875rem', textTransform: 'none' }}
            onClick={() => navigate('/resendemailverification')}
          >
            Resend Email?
          </Button>

          <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'center' }}>
            If you don't see the email in your inbox, please check your spam or junk folder. Thank
            you!
          </Typography>
        </Paper>
      </Box>
    </Box>
  )
}
