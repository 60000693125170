import { Grid, Typography } from '@mui/material'
import { Marketing2Image } from '../../../components/icons'
import { Menu } from './Menu'

export const SectionTwo = () => {
  return (
    <Grid
      item
      container
      flexDirection="row"
      flexGrow="1"
      flexWrap="wrap"
      justifyContent="center"
      gap={2}
      sx={{ marginTop: '4rem' }}
    >
      <Grid item xs={12} justifyContent="center" sx={{ textAlign: 'center' }}>
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          Unleash the power of Grantmatch
        </Typography>
      </Grid>
      <Grid item container xs={12} justifyContent="center" flexDirection="row">
        <Grid item xs={12} sm={6} textAlign="center">
          <Marketing2Image
            width="100%"
            height="100%"
            style={{ width: '100%', height: '100%', maxWidth: '400px' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Menu />
        </Grid>
      </Grid>
    </Grid>
  )
}
