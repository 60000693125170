import { AppBar, Stack, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { AuthSelector } from '../../store/auth'
import { GrantMatch } from '../icons'
import { Navigation, NavPopover } from '../navigation'
import { AccountPopover } from './AccountPopover'

export const BaseHeader = () => {
  const theme = useTheme()
  const isLoggedIn = useSelector(AuthSelector.hasToken)

  const grantMatchIcon = isLoggedIn ? '/home' : '/'

  return (
    <AppBar color="transparent" position="relative" elevation={0}>
      <Stack
        spacing={1}
        height="60px"
        direction="row"
        alignItems="center"
        component="header"
        sx={{
          background: 'white',
          padding: '0 1.5rem',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Stack direction="row">
          <NavLink to={grantMatchIcon}>
            <GrantMatch sx={{ fontSize: '7rem' }} />
          </NavLink>
        </Stack>
        <Stack
          direction="row"
          flexGrow={1}
          justifyContent="space-between"
          height="100%"
          sx={{
            [theme.breakpoints.down(768)]: {
              display: 'none',
            },
          }}
        >
          <Navigation />
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          <AccountPopover />
          <NavPopover />
        </Stack>
      </Stack>
    </AppBar>
  )
}
