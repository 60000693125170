import { Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Table } from '../../../../../../../components/table'
import { CurrentUserSelector } from '../../../../../../../store/currentUser'
import { MemberActionMenu } from '../MemberActionMenu'

const Table_Headers = ['Full name', 'Email', 'User role', 'Technology count', 'Action']

const headerCells = Table_Headers.map((header, index) => ({
  content: (
    <Typography key={index} color="secondary" fontWeight={700} fontSize="0.9rem">
      {header}
    </Typography>
  ),
}))

interface CompanyMembersTableProps {
  handleChangeRole: Function
  handleRemoveUser: Function
}

export const CompanyMembersTable: FC<CompanyMembersTableProps> = ({
  handleChangeRole,
  handleRemoveUser,
}) => {
  const theme = useTheme()

  const companyMembers = useSelector(CurrentUserSelector.makeCompanyMembers)

  const rows =
    companyMembers &&
    companyMembers.map((rowCell) => {
      const role = rowCell.isAdmin ? 'Admin' : 'Member'

      const actionMenu = (
        <MemberActionMenu
          handleChangeRole={() => handleChangeRole(rowCell._id, role.toLocaleLowerCase())}
          handleRemoveUser={() =>
            handleRemoveUser(rowCell._id, rowCell.name, role.toLocaleLowerCase())
          }
        />
      )

      return {
        rowProps: {
          sx: { '&:hover': { cursor: 'pointer', background: theme.palette.grey[100] } },
        },
        cells: [
          {
            content: (
              <Typography
                color="secondary"
                fontWeight={theme.typography.fontWeightBold}
                fontSize="0.875rem"
              >
                {rowCell.name}
              </Typography>
            ),
            cellProps: {
              sx: { maxWidth: 400 },
            },
          },

          {
            content: (
              <Typography
                color="secondary.light"
                fontWeight={theme.typography.fontWeightMedium}
                fontSize="0.875rem"
              >
                {rowCell.email}
              </Typography>
            ),
            cellProps: {
              sx: { display: { xs: 'none', sm: 'table-cell' } },
            },
          },
          {
            content: (
              <Typography
                color="secondary.light"
                fontWeight={theme.typography.fontWeightMedium}
                fontSize="0.875rem"
              >
                {role}
              </Typography>
            ),
            cellProps: {
              sx: { display: { xs: 'none', sm: 'table-cell' } },
            },
          },
          {
            content: (
              <Typography
                color="secondary.light"
                fontWeight={theme.typography.fontWeightMedium}
                fontSize="0.875rem"
              >
                {rowCell.technologyCount}
              </Typography>
            ),
            cellProps: {
              sx: { display: { xs: 'none', sm: 'table-cell' } },
            },
          },
          {
            content: actionMenu,
            cellProps: {
              sx: { display: { xs: 'none', sm: 'table-cell' } },
            },
          },
        ],
      }
    })

  return (
    <>
      {!!rows.length && (
        <Table
          containerProps={{
            sx: {
              boxShadow: 'none',
              background: 'transparent',
            },
          }}
          head={{
            containerProps: {
              sx: { borderRadius: '8px', background: theme.palette.grey[200], width: '100%' },
            },
            rows: [{ cells: headerCells || [] }],
          }}
          body={{ rows }}
        />
      )}
    </>
  )
}
