import { Box } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { InvalidSubHeader } from '../headers'

import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { AuthSelector } from '../../store/auth'

interface InvalidSubLayoutProps extends PropsWithChildren {
  header?: JSX.Element
}

export const InvalidSubLayout: FC<InvalidSubLayoutProps> = ({ children, header }) => {
  const isLoggedIn = useSelector(AuthSelector.hasToken)
  const isSubValid = useSelector(AuthSelector.isSubscriptionValid)

  if (isLoggedIn && isSubValid) {
    return <Navigate to="/home" />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        minHeight: '100vh',
        maxHeight: '100vh',
      }}
    >
      <InvalidSubHeader />
      <Box
        component="div"
        sx={{
          overflowY: 'auto',
          display: 'flex',
          flexGrow: 1,
          padding: '40px 24px 24px 24px',
        }}
      >
        {!isLoggedIn && <Navigate to="/login" />}
        <Outlet />
      </Box>
    </Box>
  )
}
