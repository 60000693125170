import { Chip, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { Draggable, Droppable } from '../../../../../components/Drag&Drop'
import { EmptySavedStateIcon } from '../../../../../components/icons'
import { companyPreferencesSelector } from '../../../../../store/companyPreferences'
import { TechGrantSelectors } from '../../../../../store/technology'
import { EmptyState } from '../EmptyState'
import { KanbanViewCard } from './KanbanViewCard'

export const KanbanViewColumns = () => {
  const grantResponses = useSelector((state) => TechGrantSelectors.makeKanbanGrantsSummary(state))
  const progressStatuses = useSelector(companyPreferencesSelector.makeGrantsProgressStatuses)

  return grantResponses && grantResponses.length > 0 ? (
    <Grid container spacing={1}>
      {grantResponses &&
        !!grantResponses.length &&
        progressStatuses.map(({ index, status, textColor, backgroundColor }) => (
          <Grid key={index} item md={3} sm={6} xs={12}>
            <Chip
              size="small"
              sx={{
                mb: 2,
                px: 1,
                height: 32,
                width: 'min-content',
                borderRadius: '20px',
                background: backgroundColor,
              }}
              label={
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 700,
                    textTransform: 'capitalize',
                    color: textColor,
                  }}
                >
                  {status}
                </Typography>
              }
            />

            <Droppable id={status} droppableSx={{ height: `calc(100% - 44px)` }}>
              {grantResponses.map((grant) => {
                const { refId, agency, close_date, topicId, title, progressStatus } = grant

                return (
                  progressStatus === status && (
                    <Draggable id={refId} key={refId} data={{ topicId }}>
                      <KanbanViewCard agency={agency} deadline={close_date} title={title} />
                    </Draggable>
                  )
                )
              })}
            </Droppable>
          </Grid>
        ))}
    </Grid>
  ) : (
    <EmptyState
      message="You do not have any grants saved at the moment"
      icon={EmptySavedStateIcon}
    />
  )
}
