import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const MarketingImage3: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 262 261" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M161.013 185.787C170.932 180.126 179.371 172.197 185.638 162.649C191.905 153.101 195.822 142.204 197.07 130.851C198.318 119.499 196.861 108.012 192.817 97.3301C188.773 86.6486 182.259 77.0761 173.806 69.3956C165.352 61.715 155.201 56.1447 144.182 53.1403C133.163 50.1358 121.59 49.7826 110.408 52.1095C99.2261 54.4363 88.7541 59.3772 79.8483 66.528C70.9426 73.6787 63.8562 82.8361 59.1687 93.2511C50.77 109.934 48.7948 141.944 39.661 147.169L29.214 153.129C28.2131 153.701 27.4801 154.646 27.1757 155.758C26.8713 156.87 27.0206 158.057 27.5906 159.058L33.3559 169.157L38.6473 178.441L44.4125 188.539C44.6951 189.035 45.0727 189.471 45.5236 189.821C45.9745 190.171 46.49 190.429 47.0407 190.579C47.5913 190.73 48.1662 190.771 48.7326 190.699C49.2989 190.628 49.8457 190.445 50.3415 190.163L60.7886 184.202C69.9223 178.998 98.4698 193.611 117.127 194.851C132.348 196.572 147.72 193.397 161.013 185.787Z"
        fill="#FDD305"
      />
      <circle
        cx="126.7"
        cy="122.564"
        r="47.431"
        transform="rotate(45 126.7 122.564)"
        fill="#EAEEFB"
      />
      <path
        d="M91.6562 72.6215C91.9259 73.0952 92.2863 73.5111 92.7169 73.8454C93.1474 74.1797 93.6396 74.4258 94.1653 74.5698C94.691 74.7138 95.24 74.7527 95.7808 74.6844C96.3215 74.6161 96.8435 74.4419 97.317 74.1717C108.158 67.9689 120.76 65.5621 133.124 67.3328C145.489 69.1034 156.908 74.9502 165.573 83.9464C165.947 84.3575 166.399 84.6894 166.903 84.9226C167.407 85.1557 167.953 85.2854 168.508 85.3039C169.063 85.3225 169.616 85.2295 170.135 85.0304C170.653 84.8314 171.127 84.5303 171.527 84.1451C171.927 83.7599 172.246 83.2983 172.464 82.7877C172.683 82.2771 172.797 81.7279 172.799 81.1724C172.802 80.617 172.693 80.0668 172.479 79.5542C172.265 79.0416 171.951 78.5771 171.554 78.1882C161.61 67.8548 148.501 61.1373 134.305 59.1009C120.11 57.0644 105.64 59.8258 93.1924 66.9469C92.7186 67.2182 92.3029 67.5804 91.9691 68.0126C91.6354 68.4447 91.3902 68.9385 91.2475 69.4656C91.1048 69.9927 91.0674 70.5427 91.1376 71.0843C91.2077 71.6258 91.384 72.1482 91.6562 72.6215Z"
        fill="#FFEE99"
      />
      <path
        d="M178.319 94.2195C177.605 94.6269 176.787 94.8135 175.968 94.7557C175.148 94.6979 174.364 94.3982 173.715 93.8947C173.065 93.3912 172.58 92.7064 172.32 91.927C172.06 91.1476 172.037 90.3085 172.254 89.5161C172.471 88.7236 172.918 88.0134 173.539 87.4751C174.16 86.9369 174.927 86.5949 175.742 86.4924C176.557 86.3899 177.384 86.5316 178.119 86.8994C178.854 87.2672 179.463 87.8447 179.869 88.5588C180.139 89.0322 180.314 89.5542 180.382 90.095C180.45 90.6358 180.411 91.1847 180.267 91.7104C180.123 92.2361 179.877 92.7283 179.543 93.1588C179.209 93.5894 178.793 93.9498 178.319 94.2195Z"
        fill="#FFEE99"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.55">
        <path
          d="M85.6439 62.3451C61.8515 80.5151 47.0395 116.542 68.9787 154.993C90.9179 193.444 130.254 197.526 157.209 187.8C144.805 193.958 130.888 196.406 117.128 194.851C98.4911 193.59 69.9228 178.998 60.7855 184.206L50.3384 190.166C49.8426 190.449 49.2959 190.631 48.7295 190.703C48.1631 190.774 47.5882 190.733 47.0376 190.583C46.487 190.432 45.9715 190.174 45.5206 189.824C45.0696 189.474 44.6921 189.039 44.4095 188.542L38.6443 178.444L33.3528 169.16L27.5876 159.061C27.0175 158.06 26.8683 156.873 27.1727 155.761C27.477 154.65 28.2101 153.704 29.2109 153.133L39.6336 147.162C48.7778 141.947 50.7425 109.926 59.1413 93.2439C64.8157 80.6071 74.0186 69.8777 85.6439 62.3451Z"
          fill="#FFC043"
        />
      </g>
      <path
        d="M214.677 71.873L194.925 83.1387"
        stroke="#FFC043"
        strokeWidth="2.478"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M186.492 40.7474L172.976 59.0289"
        stroke="#FFC043"
        strokeWidth="2.478"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.514 23.1941L142.533 45.3772"
        stroke="#FFC043"
        strokeWidth="2.478"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M227.127 111.975L204.508 114.305"
        stroke="#FFC043"
        strokeWidth="2.478"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M222.399 154.464L200.767 147.462"
        stroke="#FFC043"
        strokeWidth="2.478"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
