import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const MagnifyTime: FC<SvgIconProps> = (props) => {
  const { fill = 'black', ...rest } = { ...props }
  return (
    <SvgIcon viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.5583 24.5174C37.7933 23.3607 37.9166 22.1624 37.9166 20.9357C37.9166 11.0474 29.8883 3.01904 19.9999 3.01904C10.1116 3.01904 2.08325 11.0474 2.08325 20.9357C2.08325 30.824 10.1116 38.8524 19.9999 38.8524C21.2266 38.8524 22.4249 38.729 23.5816 38.494C24.2583 38.3557 24.6949 37.6957 24.5583 37.019C24.4199 36.344 23.7599 35.9057 23.0849 36.044C22.0883 36.2457 21.0566 36.3524 19.9999 36.3524C11.4916 36.3524 4.58325 29.444 4.58325 20.9357C4.58325 12.4274 11.4916 5.51904 19.9999 5.51904C28.5083 5.51904 35.4166 12.4274 35.4166 20.9357C35.4166 21.9924 35.3099 23.024 35.1083 24.0207C34.9699 24.6957 35.4083 25.3557 36.0833 25.494C36.7599 25.6307 37.4199 25.194 37.5583 24.5174Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5832 11.769V19.584L13.2832 25.8857C12.7949 26.3724 12.7949 27.1657 13.2832 27.6524C13.7699 28.1407 14.5632 28.1407 15.0499 27.6524L21.7166 20.9857C21.9516 20.7524 22.0832 20.434 22.0832 20.1024V11.769C22.0832 11.079 21.5232 10.519 20.8332 10.519C20.1432 10.519 19.5832 11.079 19.5832 11.769Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3333 23.019C24.8833 23.019 22.0833 25.819 22.0833 29.269C22.0833 32.719 24.8833 35.519 28.3333 35.519C31.7833 35.519 34.5833 32.719 34.5833 29.269C34.5833 25.819 31.7833 23.019 28.3333 23.019ZM28.3333 25.519C30.4033 25.519 32.0833 27.199 32.0833 29.269C32.0833 31.339 30.4033 33.019 28.3333 33.019C26.2633 33.019 24.5833 31.339 24.5833 29.269C24.5833 27.199 26.2633 25.519 28.3333 25.519Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.5498 36.7191L33.3832 32.5524C32.8965 32.0641 32.1032 32.0641 31.6165 32.5524C31.1282 33.0391 31.1282 33.8324 31.6165 34.3191L35.7832 38.4857C36.2698 38.9741 37.0632 38.9741 37.5498 38.4857C38.0382 37.9991 38.0382 37.2057 37.5498 36.7191Z"
        fill={fill}
      />
    </SvgIcon>
  )
}
