import { CheckCircleOutline } from '@mui/icons-material'
import { Box, Button, Chip, Grid, List, ListItem, Typography, useTheme } from '@mui/material'

import { useEffect, useRef } from 'react'
import {
  ClipBoardCheck,
  GrowthChart,
  HappyFace,
  LighBulbColored,
  LightBulbGM,
  MagnifyTime,
  Marketing1Image,
  MarketingImage3,
  MarketingImage4,
  Rocket,
} from '../../components/icons'
import { Faq } from './Faq'
import { SectionTwo } from './SectionTwo'
import { WelcomePageFooter } from './WelcomePageFooter'

export const Welcome = () => {
  const theme = useTheme()
  const formEle = useRef(null)

  const handleScrolltoForm = () => {
    const ele = document.getElementById('cform')
    if (ele) {
      ele.scrollIntoView({ behavior: 'smooth' })
    }
  }
  useEffect(() => {
    const script = document.createElement('script')
    //          <ScriptTag async data-uid="ab5d64777e" type="text/javascript" src="https://catalyzing-concepts.ck.page/ab5d64777e/index.js"/>
    script.src = 'https://catalyzing-concepts.ck.page/ab5d64777e/index.js'
    script.async = true
    script.setAttribute('data-uid', 'ab5d64777e')
    // @ts-ignore
    formEle.current.appendChild(script)
  }, [])

  return (
    <Box>
      <Grid container flexDirection="column" gap={4}>
        <Grid
          item
          container
          flexDirection="row"
          flexGrow="1"
          flexWrap="wrap"
          justifyContent="center"
          gap={2}
        >
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Chip label="Match, Fund, Thrive" sx={{ fontWeight: 600 }} />{' '}
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h3" sx={{ fontWeight: theme.typography.fontWeightBold }}>
              Stop Searching. Start Matching.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant="body1" sx={{ fontWeight: theme.typography.fontWeightBold }}>
              GrantMatch brings the most relevant grant opportunities to you, so you can stop your
              tedious & time consuming search processes.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }} gap={2}>
            <Button variant="contained" size="medium" sx={{ borderRadius: 8 }} href="/register">
              Start Matching Now
            </Button>
            <Typography
              variant="body1"
              component="h3"
              sx={{ mt: 2 }}
              fontWeight={theme.typography.fontWeightBold}
            >
              No credit card required.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', height: '400px' }} gap={2}>
            <Marketing1Image style={{ height: '100%', width: '100%' }} />
          </Grid>
        </Grid>
        <Grid
          item
          container
          flexDirection="row"
          flexGrow="1"
          flexWrap="wrap"
          justifyContent="center"
          gap={2}
          sx={{ marginTop: '4rem' }}
        >
          <Grid
            item
            container
            xs={12}
            sm={5}
            md={3}
            flexDirection="row"
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="center"
            sx={{
              padding: '8px',
              backgroundColor: 'grey.300',
              borderRadius: '0.75rem',
              height: '115px',
            }}
          >
            <Grid item flexShrink={1} flexBasis="auto">
              <MagnifyTime fontSize="large" />
            </Grid>
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: 600, marginLeft: '4px' }} align="center">
                85% reduction in search time
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={5}
            md={3}
            flexDirection="row"
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="center"
            sx={{
              padding: '8px',
              backgroundColor: 'grey.300',
              borderRadius: '0.75rem',
              height: '115px',
            }}
          >
            <Grid item flexShrink={1} flexBasis="auto">
              <ClipBoardCheck fontSize="large" />
            </Grid>
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: 600, marginLeft: '4px' }} align="center">
                3-4x more results
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={5}
            md={3}
            flexDirection="row"
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="center"
            sx={{
              padding: '8px',
              backgroundColor: 'grey.300',
              borderRadius: '0.75rem',
              height: '115px',
            }}
          >
            <Grid item flexShrink={1} flexBasis="auto">
              <HappyFace fontSize="large" />
            </Grid>
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: 600, marginLeft: '4px' }} align="center">
                100% less headaches
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <SectionTwo />
        <Grid item container flexDirection="row" sx={{ marginTop: '4rem' }}>
          <Grid item xs={12} sx={{ textAlign: 'center', marginBottom: '2rem' }}>
            <Typography variant="h4" sx={{ fontWeight: 600 }}>
              Your Arsenal for Grant Domination
            </Typography>
          </Grid>
          <Grid item container xs={12} sx={{ textAlign: 'center' }} gap={1} justifyContent="center">
            <Grid
              item
              container
              flexDirection="column"
              flexWrap="nowrap"
              xs={12}
              sm={5}
              sx={{
                padding: '12px',
                height: 'auto',
                maxWidth: '400px',
                backgroundColor: '#ECEFFB',
                borderRadius: '1rem',
              }}
            >
              <Grid item flexGrow={1}>
                <LightBulbGM
                  sx={{ height: 'auto', width: '100%', maxWidth: '188px', display: 'inline-block' }}
                />
              </Grid>
              <Grid item flexShrink={1} flexBasis="auto">
                <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
                  Seamless Tracking
                </Typography>
                <Typography variant="body1">
                  Give each grant a status based on it's stage in the pipeline. This lets you
                  prioritize the opportunities, communicate clearly to the team, and track your wins
                  & losses.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              flexDirection="column"
              xs={12}
              sm={5}
              sx={{
                padding: '12px',
                height: 'auto',
                maxWidth: '400px',
                backgroundColor: '#FFEE99',
                borderRadius: '1rem',
                overflow: 'hidden',
              }}
            >
              <Grid item sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                <Rocket
                  sx={{ height: 'auto', width: '100%', maxWidth: '300px', display: 'inline-block' }}
                />
              </Grid>
              <Grid item sx={{ flexShrink: 1, flexBasis: 'auto', padding: '8px 0' }}>
                <Typography
                  variant="h5"
                  fontWeight={theme.typography.fontWeightBold}
                  sx={{ wordWrap: 'break-word' }}
                >
                  Beyond the Database
                </Typography>
                <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
                  We don't just find grants, we help you win them. When you sign, you will receive
                  ten (10) hours of consulting services. With this time we can help review your
                  pipeline, advise on the prioritization plan, and develop an execution strategy.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              flexDirection="column"
              xs={12}
              sm={5}
              sx={{
                padding: '12px',
                height: 'auto',
                maxWidth: '400px',
                backgroundColor: '#DFF8D5',
                borderRadius: '1rem',
                overflow: 'hidden',
              }}
            >
              <Grid item sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
                <MarketingImage4
                  sx={{
                    height: 'auto',
                    width: '100%',
                    maxWidth: { xs: '999px', sm: '160px' },
                    display: 'inline-block',
                  }}
                />
              </Grid>
              <Grid item sx={{ flexShrink: 1, flexBasis: 'auto', padding: '8px 0' }}>
                <Typography
                  variant="h5"
                  fontWeight={theme.typography.fontWeightBold}
                  sx={{ wordWrap: 'break-word' }}
                >
                  Team Collaboration
                </Typography>
                <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
                  Add members of your team to the technology products they're working on. This way
                  everyone from the PI to Biz Dev can stay up to date on the action.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              flexDirection="column"
              flexWrap="nowrap"
              xs={12}
              sm={5}
              sx={{
                padding: '12px',
                height: 'auto',
                maxWidth: '400px',
                backgroundColor: '#EAEEFB',
                borderRadius: '1rem',
              }}
            >
              <Grid item flexGrow={1}>
                <MarketingImage3
                  sx={{ height: 'auto', width: '100%', maxWidth: '230px', display: 'inline-block' }}
                />
              </Grid>
              <Grid item flexShrink={1} flexBasis="auto">
                <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
                  Quick Search
                </Typography>
                <Typography variant="body1">
                  We get it - sometimes you just want to see what's out there. So we made a quick
                  search option with advanced filters. Now you can find specific results, add them
                  to your technology, all while improving your recommendations.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          flexDirection="row"
          flexGrow="1"
          flexWrap="wrap"
          justifyContent="center"
          gap={2}
          sx={{ marginTop: '4rem', textAlign: 'center' }}
        >
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Invest in Growth
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              This is our introductory rate for early adopting companies. Join today to lock in your
              rate and start finding grants faster.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <GrowthChart height="60px" width="60px" sx={{ height: '60px', width: '60px' }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ fontWeight: 600 }}>
              $500/year
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <List sx={{ maxWidth: '600px' }}>
              <ListItem>
                <Typography variant="body1" component="h5" sx={{ width: '100%' }}>
                  <CheckCircleOutline
                    fontSize="small"
                    sx={{ color: 'success.main', paddingTop: '2px', verticalAlign: 'top' }}
                  />
                  Unlimited Searches
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1" component="h5">
                  <CheckCircleOutline
                    fontSize="small"
                    sx={{ color: 'success.main', paddingTop: '2px', verticalAlign: 'top' }}
                  />
                  Unlimited Technologies
                </Typography>
              </ListItem>
              <ListItem>
                {' '}
                <Typography variant="body1" component="h5">
                  <CheckCircleOutline
                    fontSize="small"
                    sx={{ color: 'success.main', paddingTop: '2px', verticalAlign: 'top' }}
                  />
                  Unlimited Users
                </Typography>
              </ListItem>
              <ListItem>
                {' '}
                <Typography variant="body1" component="h5">
                  <CheckCircleOutline
                    fontSize="small"
                    sx={{ color: 'success.main', paddingTop: '2px', verticalAlign: 'top' }}
                  />
                  Precision AI Grant Matching
                </Typography>
              </ListItem>
              <ListItem>
                {' '}
                <Typography variant="body1" component="h5">
                  <CheckCircleOutline
                    fontSize="small"
                    sx={{ color: 'success.main', paddingTop: '2px', verticalAlign: 'top' }}
                  />
                  10 Hours of strategy consultation
                </Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Grid
            item
            container
            spacing={1}
            flexDirection="row"
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="center"
            xs={11}
            sm={6}
            md={5}
            sx={{ padding: '12px', backgroundColor: '#FFEE99', borderRadius: '1rem' }}
          >
            <Grid item flexShrink={1} flexBasis="auto">
              <LighBulbColored fontSize="large" />
            </Grid>
            <Grid item flexGrow={0}>
              <Typography variant="body1" textAlign="center">
                On average, every technology project is eligible for 2-4 SBIR grants. Stop missing
                these opportunities & start matching today!
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', mt: 4 }}>
            <Button
              variant="contained"
              size="medium"
              sx={{ borderRadius: 8, mx: 1 }}
              href="/register"
            >
              Start your trial today!
            </Button>
            <Typography
              variant="body1"
              component="h3"
              textAlign="center"
              sx={{ mt: 2 }}
              fontWeight={theme.typography.fontWeightBold}
            >
              No credit card required.
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={12} justifyContent="center">
          <Grid item xs={12}>
            <Faq />
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center" sx={{ marginTop: '4rem' }}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h4" sx={{ fontWeight: 600 }}>
              Stay In Touch
            </Typography>
          </Grid>
          <Grid item ref={formEle} id="cform"></Grid>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <WelcomePageFooter />
        </Grid>
      </Grid>
    </Box>
  )
}
