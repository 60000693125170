import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { AppDispatch } from '../../../../store'
import { AuthActions } from '../../../../store/auth'
import { FormsActions } from '../../../../store/forms'
import { SnackbarActions } from '../../../../store/snackbar'
import { VerificationError, VerificationPending, VerificationSuccess } from './subcomponents'

type PageState = 'pending' | 'success' | 'error'

export const VerifyCompanyInvitation = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const { enqueueSnackbar } = useSnackbar()
  const [verification, setVerification] = useState<PageState>('pending')

  useEffect(() => {
    const verify = async () => {
      try {
        const result = await dispatch(
          AuthActions.verifyCompanyInvitationAsync({ email: email as string }),
        )

        if (AuthActions.verifyCompanyInvitationAsync.fulfilled.match(result)) {
          setVerification('success')
          dispatch(FormsActions.setValue({ id: 'invitationEmail', email }))
        }

        if (AuthActions.verifyCompanyInvitationAsync.rejected.match(result)) {
          const { message = 'error' } = result.payload as any
          throw new Error(message)
        }
      } catch (error: any) {
        setVerification('error')
        dispatch(
          SnackbarActions.showSnackbar({
            message: error.message,
          }),
        )
      }
    }
    if (email) {
      verify()
    }
  }, [email])

  return (
    <>
      {verification === 'error' && <VerificationError />}
      {verification === 'success' && <VerificationSuccess email={email} />}
      {verification === 'pending' && <VerificationPending />}
    </>
  )
}
