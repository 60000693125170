import { CheckRounded, NavigateNext } from '@mui/icons-material'
import { Box, Breadcrumbs, Stack, Typography, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../store'
import {
  CreateTechnologyActions,
  CreateTechnologySelectors,
} from '../../../store/createTechnologyForm'

const BreadcrumbList = [
  {
    value: 0,
    label: 'Add Technology',
  },
  {
    value: 2,
    label: 'Select Keywords',
  },
]

export const AddTechnologyBreadcrumbs = () => {
  const dispatch = useDispatch<AppDispatch>()
  const theme = useTheme()

  const stage = useSelector(CreateTechnologySelectors.getStage)
  const stageStatuses = useSelector(CreateTechnologySelectors.getStageStatuses)

  const handleBreadCrumbClick = (index: number, value: number) => {
    if (index === 0) {
      dispatch(CreateTechnologyActions.setState(value))
    } else if (index !== 0) {
      stageStatuses[value - 2] && dispatch(CreateTechnologyActions.setState(value))
    }
  }
  return (
    <Box
      component="div"
      sx={{ py: 2, my: 1, px: { xs: 0, sm: 4 }, background: { xs: 'none', sm: '#F1F5F9' } }}
    >
      <Breadcrumbs separator={<NavigateNext fontSize="medium" sx={{ color: '#94A3B8' }} />}>
        {BreadcrumbList.map((item, index) => {
          const { label, value } = item

          const isCompleted = stageStatuses[value]

          return (
            <Stack
              gap={1}
              key={label}
              component="div"
              direction="row"
              alignItems="center"
              onClick={() => {
                handleBreadCrumbClick(index, value)
              }}
            >
              {isCompleted ? (
                <CheckRounded color="primary" sx={{ display: { xs: 'none', sm: 'block' } }} />
              ) : (
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      color: isCompleted || stage === value ? '#100A37' : '#bcc5d3',
                    }}
                  >
                    {index + 1}
                  </Typography>
                </Box>
              )}
              <Typography
                sx={{
                  fontWeight:
                    isCompleted || stage === value ? 'bold' : theme.typography.fontWeightMedium,
                  color: isCompleted || stage === value ? '#100A37' : '#bcc5d3',
                  fontSize: { xs: '0.92rem', sm: '1rem' },
                  [theme.breakpoints.down('sm')]: {
                    color: stage === value ? '#100A37' : '#bcc5d3',
                    fontWeight: theme.typography.fontWeightBold,
                  },
                }}
              >
                {label}
              </Typography>
            </Stack>
          )
        })}
      </Breadcrumbs>
    </Box>
  )
}
