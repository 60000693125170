import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { AuthApi } from '../../../services'
import { IAuth } from '../../../types'
import { normalizeError } from '../../../utils'
import { AuthInitialState } from '../userAuth.slice'

export const userSignUpAsync = createAsyncThunk(
  'auth/register',
  async (
    {
      email,
      password,
      recaptchaToken,
    }: { email: string; password: string; recaptchaToken: string },
    { rejectWithValue },
  ) => {
    try {
      const req = await AuthApi.RegisterUser({ email, password, recaptchaToken })
      return (req as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'signup')
      return rejectWithValue(normalized)
    }
  },
)

export const userSignUpAsyncBuilder = (builder: ActionReducerMapBuilder<AuthInitialState>) => {
  builder.addCase(userSignUpAsync.pending, (state) => {
    state.loading = true
    state.error = ''
  })
  builder.addCase(userSignUpAsync.fulfilled, (state, action: PayloadAction<IAuth>) => {
    state.loading = false
    state.success = true
    state.data = action.payload
  })
  builder.addCase(userSignUpAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
