import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath, Navigate, Route, Routes, useLocation } from 'react-router-dom'

import './App.css'
import {
  AuthorizationLayout,
  BaseLayout,
  InvalidSubLayout,
  RegistrationStepsLayout,
  WelcomeLayout,
} from './components/layouts'
import { PublicLayout } from './components/layouts/PublicLayout'
import { AppDispatch } from './store'
import { AuthSelector } from './store/auth'
import { CurrentUserActions } from './store/currentUser'
import {
  AddTechnology,
  EmailAuthPage,
  ForgotPassword,
  Grant,
  Home,
  InvalidSubscription,
  Login,
  PublicGrant,
  ResetPassword,
  Search,
  StripeCheckout,
  StripePaymentRedirect,
  Technology,
  TechnologyGrants,
  UserProfile,
  Welcome,
} from './views'
import { ResendEmailVerification } from './views/auth/ResendEmailVerification'
import {
  CompletionPage,
  RegistrationSteps,
  SignUp,
  VerifyCompanyInvitation,
} from './views/auth/SignUp'
import { Solicitation } from './views/Solicitation'
import { AboutUs } from './views/Welcome/AboutUs/AboutUs'
import { TermlyEmbed } from './views/Welcome/TermlyEmbed'

const App = () => {
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const isLoggedIn = useSelector(AuthSelector.hasToken)

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(CurrentUserActions.fetchCurrentUserAsync())
    }
  }, [isLoggedIn, dispatch])

  useEffect(() => {
    if (window.fbq) {
      const isMatch = ['/welcome', '/aboutus', '/privacy', '/termsofuse'].some((path) =>
        matchPath(path, location.pathname),
      )
      if (isMatch) {
        window.fbq('track', 'PageView')
      }
    }
  }, [location])

  return (
    <Routes>
      {/* app outside of authentication*/}
      <Route path="/" element={<AuthorizationLayout />}>
        <Route key="login" path="login" element={<Login />} />
        <Route key="register" path="register" element={<SignUp />} />
        <Route key="emailauth" path="validateemail" element={<EmailAuthPage />} />
        <Route key="passwordreset" path="resetpassword" element={<ResetPassword />} />
        <Route
          key="emailreset"
          path="resendemailverification"
          element={<ResendEmailVerification />}
        />
        <Route key="forgotpassword" path="forgotpassword" element={<ForgotPassword />} />
        <Route key="checkout-form" path="checkoutForm" element={<StripeCheckout />} />
        <Route
          key="privacy"
          path="privacy"
          element={<TermlyEmbed dataId="e1cfef2d-a2af-44d8-9bc1-b77168a9acd1" />}
        />
        <Route key="payment-redirect" path="paymentRedirect" element={<StripePaymentRedirect />} />
        <Route
          key="companyinvite"
          path="validateCompanyInvitation"
          element={<VerifyCompanyInvitation />}
        />
      </Route>

      <Route
        key="registrationsteps"
        path="/"
        element={isLoggedIn ? <RegistrationStepsLayout /> : <Navigate to="/login" />}
      >
        <Route key="steps" path="steps" element={<RegistrationSteps />} />
        <Route key="completed" path="completed" element={<CompletionPage />} />
      </Route>

      <Route key="invalid-sub" path="/" element={<InvalidSubLayout />}>
        <Route
          key="invalidSubscription"
          path="invalidSubscription"
          element={<InvalidSubscription />}
        />
      </Route>

      <Route
        key="marketing"
        path="/"
        element={isLoggedIn ? <Navigate to="/home" /> : <WelcomeLayout />}
      >
        <Route key="welcome" path="welcome" element={<Welcome />} />
        <Route key="aboutus" path="aboutus" element={<AboutUs />} />
        <Route
          key="termsofuse"
          path="termsofuse"
          element={<TermlyEmbed dataId="771408b4-0e2f-4aa2-b280-e4cd23130bca" />}
        />
        <Route
          key="useragreement"
          path="useragreement"
          element={<TermlyEmbed dataId="7ace0ca1-a5df-422c-9bc4-9cddd6aae7fc" />}
        />
      </Route>

      <Route key="public" path="/" element={<PublicLayout />}>
        <Route key="sharedGrant" path="/shared/grants/:id" element={<PublicGrant />} />
      </Route>

      <Route key="base" path="/" element={<BaseLayout />}>
        <Route
          key="home"
          path="home"
          element={<Home />}
          handle={{
            middleware: (a: any, b: any) => {
              console.log(123, a, b)
            },
          }}
        />
        <Route key="browse" path="browse/*" element={<Search />} />
        <Route key="analytics" path="analytics" element={<div></div>} />
        <Route key="more" path="more" element={<div></div>} />
        <Route key="addTechnology" path="addTechnology/*" element={<AddTechnology />} />
        <Route key="grant" path="grants/:id" element={<Grant />} />
        <Route key="technologyGrants" path="technology/:id/grants" element={<TechnologyGrants />} />
        <Route key="profile" path="profile/*" element={<UserProfile />} />
        <Route key="solicitationview" path="solicitations/:id" element={<Solicitation />} />

        <Route key="technology" path="technologies/:id" element={<Technology />} />
      </Route>

      <Route
        key="*"
        path="*"
        element={isLoggedIn ? <Navigate to="/home" replace /> : <Navigate to="/welcome" replace />}
      />
    </Routes>
  )
}

export default App
