import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment'
import { IGrant, IMatchedGrant, ISolicitation, MatchedGrantStatus } from '../../types/Grants'
import { convertPhase } from '../../utils'
import { RootState } from '../store'
import { initialGrantsData } from './grants.slice'

const grantsColumnsToRemove = ['status', 'refId', 'like']

const getGrants = (state: RootState) => state.technology.grants.data
const getSavedGrants = (state: RootState) =>
  state.technology.grants.data.selected || initialGrantsData
const getMatchedGrants = (state: RootState) =>
  state.technology.grants.data.recommended || initialGrantsData
const getRejectedGrants = (state: RootState) =>
  state.technology.grants.data.rejected || initialGrantsData
const getLoading = (state: RootState) => state.technology.grants.loading
const error = (state: RootState) => state.technology.grants.error

const makeSavedGrantsPaging = createSelector(getSavedGrants, ({ paging }) => paging)
const makeMatchedGrantsPaging = createSelector(getMatchedGrants, ({ paging }) => paging)
const makeRejectedGrantsPaging = createSelector(getRejectedGrants, ({ paging }) => paging)

const makeGrantsSummary = createSelector(
  getGrants,
  (_: any, status: MatchedGrantStatus) => status,
  (grants, status) => {
    const data = grants[status]
    const _grants = data.grants

    return _grants.map((g: IMatchedGrant) => {
      const { solicitation = {} as ISolicitation, grant = {} as IGrant } = g

      const phaseValue = convertPhase(solicitation.phase)

      return {
        _id: grant._id,
        refId: g._id,
        status: g.status,
        progressStatus: g.progressStatus || '',
        topicId: grant.topicId,
        title: grant.title || '',
        phase: phaseValue,
        agency: solicitation.agency || '',
        solicitationLink: solicitation?.solicitation_link || 'N/A',
        like: g.like,
        agencyUrl: solicitation?.agency_url || 'N/A',
        outcome: g.outcome || 'N/A',
        feedback: g.feedback || '',
        close_date:
          (solicitation.close_date && moment.utc(solicitation.close_date).format('MM/DD/YYYY')) ||
          '',
        type: g.status,
      }
    })
  },
)

const makeKanbanGrantsSummary = createSelector(
  (_: any) => makeGrantsSummary(_, 'selected'),
  (grants) => {
    return grants.filter((g) => g.outcome === 'N/A')
  },
)

const makeExportGrants = createSelector(
  (_: any, status: MatchedGrantStatus) => status,
  (_: any, status: MatchedGrantStatus) => makeGrantsSummary(_, status),
  (_: any, grants: any[]) => {
    if (!grants || !grants.length) return null

    const updatedGrants = grants.map((grant) => {
      const { progressStatus, topicId, ...rest } = grant
      return {
        Status: progressStatus,
        topic: topicId,
        ...rest,
      }
    })

    const columns = Object.keys(updatedGrants[0]).filter(
      (column) => !grantsColumnsToRemove.includes(column),
    )

    const rows: string[][] = updatedGrants.map((item: any) => {
      const { status, refId, like, ...filteredItem } = item

      return columns.map((c) => {
        const cellValue = (filteredItem as any)[c]
        if (cellValue === 'NA' || cellValue === null || cellValue === undefined) {
          return ''
        }

        if (cellValue && isNaN(Number(cellValue))) {
          return cellValue.replace(/\r|\n/g, '')
        }

        return cellValue
      })
    })

    return { rows, columns }
  },
)

const makeGrantsIds = createSelector(
  getGrants,
  (_: any, status: MatchedGrantStatus) => status,
  (grants, status) => {
    const data = grants[status]
    const _grants = data.grants

    return _grants.map((g: IMatchedGrant) => {
      const { grant = {} as IGrant } = g

      return grant._id
    })
  },
)

export const TechGrantSelectors = {
  error,
  getGrants,
  getLoading,
  makeGrantsIds,
  makeGrantsSummary,
  makeExportGrants,
  makeSavedGrantsPaging,
  makeMatchedGrantsPaging,
  makeRejectedGrantsPaging,
  makeKanbanGrantsSummary,
}
