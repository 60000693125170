import { Box, Stack, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { CurrentUserSelector } from '../../store/currentUser'
import { AddTechnologyButton } from './subComponents/AddTechnologyButton'
import { Technologies } from './subComponents/Technologies'

export const Home: FC = () => {
  const theme = useTheme()
  const { firstName = '' } = useSelector(CurrentUserSelector.userDetail)

  return (
    <Box width="100%">
      <Box>
        <Typography
          fontSize="1.5rem"
          variant="h5"
          fontWeight={theme.typography.fontWeightMedium}
          color="secondary"
        >
          Welcome, {firstName}
        </Typography>
        <Typography
          fontSize="1rem"
          fontWeight={theme.typography.fontWeightMedium}
          color="secondary.light"
          my={1}
        >
          Discover your perfect grants!
        </Typography>
      </Box>

      <Stack direction="row" my={4} justifyContent="space-between" alignItems="center">
        <Typography
          fontSize="1.5rem"
          variant="h5"
          fontWeight={theme.typography.fontWeightMedium}
          color="secondary"
        >
          Your technologies
        </Typography>
        <AddTechnologyButton />
      </Stack>
      <Technologies />
    </Box>
  )
}
