import { AxiosAPI } from './AxiosApi'

class UsersAPI extends AxiosAPI {
  public fetchCurrentUser = async (options = {}) => {
    return await this.GET(this.apiSchema.GET.users.currentUser(), options)
  }
  public fetchCurrentUserAvatar = async (options = {}) => {
    return await this.GET(this.apiSchema.GET.users.currentUserAvatar(), options)
  }
  public updateCurrentUserCompany = async (body: any, options = {}) => {
    return await this.PUT(this.apiSchema.PUT.users.currentUserCompany(), body, options)
  }

  public updateCurrentUser = async (body: any, options = {}) => {
    return await this.PUT(this.apiSchema.PUT.users.currentUser(), body, options)
  }

  public updateCurrentUserAvatar = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.users.currentUserAvatar(), body, options)
  }

  public fetchUsers = async (options = {}) => {
    return await this.GET(this.apiSchema.GET.users.users(), options)
  }

  public fetchRegistrationProgress = async (options = {}) => {
    return await this.GET(this.apiSchema.GET.users.registrationProgress(), options)
  }

  public updateRegistrationProgress = async (body: any, options = {}) => {
    return await this.PUT(this.apiSchema.PUT.users.registrationProgress(), body, options)
  }

  public addCompanyMembers = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.users.companyMembers(), body, options)
  }

  public fetchCompany = async (options = {}) => {
    return await this.GET(this.apiSchema.GET.users.company(), options)
  }

  public fetchCompanyMembers = async (options = {}) => {
    return await this.GET(this.apiSchema.GET.users.companyMembers(), options)
  }

  public updateCurrentUserCompanyMemberRole = async (id: string, body: any, options = {}) => {
    return await this.PUT(this.apiSchema.PUT.users.currentUserCompanyMemberRole(id), body, options)
  }

  public removeCurrentUserCompanyMember = async (id: string, body: any, options = {}) => {
    return await this.PUT(this.apiSchema.PUT.users.currentUserCompanyMember(id), body, options)
  }

  public fetchUser = async (id: string, options = {}) => {
    return await this.GET(this.apiSchema.GET.users.user(id), options)
  }

  public fetchCompanyPreferences = async (options = {}) => {
    return await this.GET(this.apiSchema.GET.users.companyPreferences(), options)
  }
}

export default new UsersAPI()
