import { AppBar, Button, Stack, useTheme } from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom'
import { WelcomeNavPopover } from '../../views/Welcome/WelcomeNavPopover'
import { GrantMatch } from '../icons'

export const WelcomeHeader = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <AppBar color="transparent" position="relative" elevation={0}>
      <Stack
        direction="row"
        height="60px"
        alignItems="center"
        spacing={1}
        component="header"
        sx={{
          background: 'white',
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          padding: '0 1.5rem',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction="row">
          <NavLink to={'/welcome'}>
            <GrantMatch sx={{ fontSize: '7rem' }} />
          </NavLink>
          <Stack
            direction="row"
            sx={{
              marginLeft: '3rem',
              [theme.breakpoints.down(768)]: {
                display: 'none',
              },
            }}
          >
            <Button
              key={'/aboutus'}
              disableRipple
              href={'/aboutus'}
              sx={{
                '&:hover': {
                  background: 'none',
                },
              }}
            >
              About Us
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" flexGrow={1} justifyContent="flex-end" height="100%">
          <Stack direction="row">
            <Button
              key={'login'}
              disableRipple
              href={'/login'}
              sx={{
                [theme.breakpoints.down(768)]: {
                  display: 'none',
                },
              }}
            >
              Login
            </Button>
            <Button
              key={'getstarted'}
              disableRipple
              onClick={() => navigate('/register')}
              variant="contained"
              sx={{ borderRadius: '999px', padding: '1rem', margin: '0.75rem' }}
            >
              Get Started
            </Button>
          </Stack>
        </Stack>
        <Stack alignItems="center">
          <WelcomeNavPopover />
        </Stack>
      </Stack>
    </AppBar>
  )
}
