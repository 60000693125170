import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Box, Button, Typography, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch } from '../../../../../store'
import { AuthActions } from '../../../../../store/auth'
import { CompanyActions } from '../../../../../store/company'
import { RegistrationProgressActions } from '../../../../../store/registrationProgress'
import { SnackbarActions } from '../../../../../store/snackbar'

export const CompletionPage: React.FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(CompanyActions.resetSteps())
  }, [])

  const generateToken = async () => {
    try {
      const actionResult = await dispatch(AuthActions.refreshJwtAsync())

      if (AuthActions.refreshJwtAsync.rejected.match(actionResult)) {
        const errorMessage = actionResult.payload as any
        throw new Error(errorMessage.message)
      }

      await dispatch(
        RegistrationProgressActions.updateRegistrationProgressAsync({
          steps: {
            overallStatus: 'complete',
          },
        }),
      )

      navigate('/home', { replace: true })
    } catch (error: any) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: error.message,
        }),
      )
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: { xs: 'auto', md: '100%' },
        boxSizing: 'border-box',
      }}
    >
      <CheckCircleOutlineIcon sx={{ fontSize: '8rem', color: theme.palette.success.main }} />
      <Typography
        variant="h4"
        fontWeight={theme.typography.fontWeightBold}
        sx={{ mt: 4, mb: 2, textAlign: 'center' }}
      >
        Welcome to our open beta group!
      </Typography>
      <Typography
        variant="body1"
        fontWeight={theme.typography.fontWeightMedium}
        fontSize="16px"
        sx={{ maxWidth: '550px', textAlign: 'center', mb: 3 }}
      >
        This is the initial version of the Grant Match application. Our team is ecstatic to have you
        here as an early adopter.
      </Typography>
      <Typography
        variant="body1"
        fontWeight={theme.typography.fontWeightMedium}
        fontSize="16px"
        sx={{ maxWidth: '490px', textAlign: 'center', mb: 4 }}
      >
        We value your feedback and insight on how we can improve the app's functionality and overall
        user experience going forward. This will help to directly raise the quality of Grant Match
        and help us to all better navigate the grant world.
      </Typography>
      <Button
        variant="contained"
        onClick={generateToken}
        sx={{ mt: 2, textTransform: 'none', fontSize: '1rem' }}
      >
        Continue to app
      </Button>
    </Box>
  )
}
