import { Box, Button, TextField, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { Form, FormikProvider, useFormik } from 'formik'
import { FileUploader } from 'react-drag-drop-files'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch } from '../../../../../store'
import { AuthSelector } from '../../../../../store/auth'
import { CompanyActions } from '../../../../../store/company'
import { CurrentUserActions, CurrentUserSelector } from '../../../../../store/currentUser'
import {
  RegistrationProgressActions,
  RegistrationProgressSelectors,
} from '../../../../../store/registrationProgress'
import { SnackbarActions } from '../../../../../store/snackbar'
import { formatPhoneNumber } from '../../../../../utils'
import { UploadContainer } from './PersonalInfo.styles'

const PersonalInfoValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phone: Yup.string()
    .matches(
      /^(?:\+1\s)?\d{3}-\d{3}-\d{4}$/,
      'Phone number format should be XXX-XXX-XXXX or 1XXX-XXX-XXXX.',
    )
    .max(15, 'phone number cannot be more than 10 digits'),
})

export const PersonalInfo = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()
  const [avatar, setAvatar] = useState<File>()
  const userRole = useSelector(AuthSelector.userRole)
  const userInitials = useSelector(CurrentUserSelector.userInitials)
  const { firstName, lastName, phone } = useSelector(CurrentUserSelector.userDetail)
  const { profile = '' } = useSelector(RegistrationProgressSelectors.progressSteps)

  useEffect(() => {
    if (profile === 'complete' || userInitials) {
      userRole !== 'ORG_MEMBER' && dispatch(CompanyActions.updateStep(1))
      userRole === 'ORG_MEMBER' && navigate('/completed', { replace: true })
    }
  }, [profile, userRole, userInitials])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: firstName || '',
      lastName: lastName || '',
      phone: formatPhoneNumber(phone) || '',
    },
    validationSchema: PersonalInfoValidationSchema,

    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await dispatch(
          CurrentUserActions.updateCurrentUserProfileAsync({
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
          }),
        )

        await dispatch(
          RegistrationProgressActions.updateRegistrationProgressAsync({
            steps: {
              profile: 'complete',
            },
          }),
        )

        dispatch(
          SnackbarActions.showSnackbar({
            message: 'Personal information collected successfully!',
            options: { variant: 'success' },
          }),
        )
      } catch (error: any) {
        if (error.response && error.response.data && error.response.data.message) {
          setErrors(error.response.data.message)
        } else {
          dispatch(
            SnackbarActions.showSnackbar({
              message: 'An error occurred while collecting personal information.',
            }),
          )
        }
        setSubmitting(false)
      }
    },
  })
  const handleAvatarChange = async (file: File) => {
    setAvatar(file)
    const formData = new FormData()
    formData.append('file', file)
    const resultAction = await dispatch(
      CurrentUserActions.updateCurrentUserAvatarAsync({ file: formData }),
    )
    if (CurrentUserActions.updateCurrentUserAvatarAsync.fulfilled.match(resultAction)) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: 'avatar uploaded successfully!',
          options: { variant: 'success' },
        }),
      )
    } else {
      const { message = 'error' } = resultAction.payload as any
      dispatch(
        SnackbarActions.showSnackbar({
          message: message,
        }),
      )
    }
  }
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, width: '100%' }}>
      <FormikProvider value={formik}>
        <Typography variant="h6" fontWeight={theme.typography.fontWeightBold}>
          Your personal information
        </Typography>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="First name"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 4 }}
            {...getFieldProps('firstName')}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
          />
          <TextField
            fullWidth
            label="Last name"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 4 }}
            {...getFieldProps('lastName')}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          />
          <TextField
            fullWidth
            type="tel"
            label="Phone number"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
            onChange={(e) => setFieldValue('phone', formatPhoneNumber(e.target.value))}
          />
          <Box my={1}>
            <Typography
              fontSize="0.875rem"
              fontWeight={theme.typography.fontWeightMedium}
              color="secondary.light"
              mb={1}
            >
              Upload avatar
            </Typography>

            <FileUploader
              multiple={false}
              name="avatar"
              handleChange={handleAvatarChange}
              types={['png', 'jpeg', 'jpg']}
            >
              <UploadContainer>
                {avatar && avatar.name ? (
                  <Typography
                    textAlign="center"
                    color="secondary.light"
                    fontSize="0.875rem"
                    fontWeight={500}
                  >
                    {avatar.name}
                  </Typography>
                ) : (
                  <>
                    <Typography
                      textAlign="center"
                      color="secondary.light"
                      fontSize="0.875rem"
                      fontWeight={theme.typography.fontWeightMedium}
                    >
                      Drop your avatar here,
                    </Typography>
                    <Typography
                      textAlign="center"
                      color="primary"
                      fontSize="0.875rem"
                      fontWeight={theme.typography.fontWeightMedium}
                    >
                      Browse
                    </Typography>
                  </>
                )}
              </UploadContainer>
            </FileUploader>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                height: '40px',
                textTransform: 'none',
                fontWeight: 700,
                fontSize: '14px',
              }}
            >
              Save and continue
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  )
}
