import { Tab, Tabs, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'

const TabItems: TabLabelProps[] = [
  {
    value: 0,
    header: 'Find More, Search Less',
    body: 'In less than five minutes, our match process finds the most relevant, open grant opportunities from all agencies.',
  },
  {
    value: 1,
    header: 'Automated Continuous Match',
    body: 'With our recommendation engine running in the background, you never have to search again. As new grant solicitations open up, we grab the opportunities and suggest them to you based on your activity.',
  },
  {
    value: 2,
    header: 'Build a Multi-Agency Strategy',
    body: ' Develop a strong Multi-Agency Strategy with an easily monitored grant pipeline and backlog of grants to pursue across all the agencies.',
  },
]

interface TabLabelProps {
  value: number
  header: string
  body: string
}
const TabLabel: FC<TabLabelProps> = ({ header, body }) => {
  return (
    <>
      <Typography variant="h5">{header}</Typography>
      <Typography variant="body1">{body}</Typography>
    </>
  )
}

export const Menu = () => {
  const [selected, updateState] = useState(0)
  const updateList = () => {
    updateState((v: number) => {
      if (v === TabItems.length - 1) {
        return 0
      }
      return v + 1
    })
  }
  useEffect(() => {
    const interval = setInterval(updateList, 3000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Tabs
      value={selected}
      orientation="vertical"
      TabIndicatorProps={{
        sx: { left: 0, right: 'unset' },
      }}
    >
      {TabItems.map((item, idx) => {
        return (
          <Tab
            key={`${item.value}_${idx}`}
            value={item.value}
            label={<TabLabel {...item} />}
            onClick={() => false}
            sx={{
              alignItems: 'flex-start',
              textAlign: 'left',
              textTransform: 'unset',
              opacity: item.value === selected ? '100%' : '50%',
            }}
          />
        )
      })}
    </Tabs>
  )
}
