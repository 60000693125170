import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const ClipBoardCheck: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.6231 4.68194H23.5965C22.9135 3.13447 21.3813 2.13574 19.6897 2.13574C17.9978 2.13574 16.4656 3.13447 15.7827 4.68194H9.75572C7.48228 4.68457 5.64009 6.52706 5.63745 8.8005V35.5172C5.64009 37.7907 7.48228 39.6332 9.75572 39.6358H29.6231C31.8966 39.6332 33.7391 37.7907 33.7417 35.5172V8.8005C33.7391 6.52706 31.8966 4.68457 29.6231 4.68194ZM16.4609 6.55694C16.9009 6.55694 17.2818 6.25079 17.3764 5.82042C17.6067 4.77217 18.5797 4.01074 19.6897 4.01074C20.7998 4.01074 21.7724 4.77217 22.0027 5.82042C22.097 6.25079 22.4779 6.55694 22.9185 6.55694H24.7774V7.3796C24.776 8.61798 23.7725 9.6214 22.5342 9.62286H16.845C15.6063 9.6214 14.6029 8.61798 14.6014 7.3796V6.55694H16.4609ZM31.8667 35.5172C31.8655 36.7559 30.8618 37.7596 29.6231 37.7608H9.75572C8.51734 37.7596 7.51392 36.7559 7.51245 35.5172V8.8005C7.51392 7.56182 8.51734 6.5584 9.75572 6.55694H12.7264V7.3796C12.7291 9.65304 14.5715 11.4952 16.845 11.4979H22.5342C24.8076 11.4952 26.6498 9.65304 26.6524 7.3796V6.55694H29.6231C30.8618 6.5584 31.8652 7.56182 31.8667 8.8005V35.5172Z"
        fill="black"
      />
      <path
        d="M24.3296 19.4576L18.1749 25.467L15.0495 22.4163C14.6818 22.0565 14.093 22.0609 13.73 22.4254C13.3673 22.7901 13.3658 23.379 13.727 23.7452L13.7396 23.7575L17.5195 27.4483C17.884 27.804 18.4655 27.804 18.8297 27.4483L25.6392 20.7997C26.0074 20.4376 26.0133 19.8461 25.6529 19.4766C25.292 19.1072 24.7008 19.099 24.3296 19.4582V19.4576Z"
        fill="black"
      />
    </SvgIcon>
  )
}
