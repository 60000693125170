import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'
import { GoogleIcon, LinkedInIcon, MicrosoftIcon } from '../../../components/icons'
import { AppDispatch, RootState } from '../../../store'
import { AuthActions, AuthSelector } from '../../../store/auth'
import { FormsSelector } from '../../../store/forms'
import { SnackbarActions } from '../../../store/snackbar'
import { AuthLoader } from '../subComponents'

const iconStyle = {
  width: 60,
  height: 60,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #E2E8F0',
  borderRadius: 1,
  margin: '0 8px',
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid').required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export const Login: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { state } = useLocation()
  const dispatch = useDispatch<AppDispatch>()

  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const [searchParams] = useSearchParams()
  const authCode: any = searchParams.get('code')

  const socialAccessToken = localStorage.getItem('accessToken')

  const formId = 'invitationEmail'
  const { email = '' } = useSelector((state: RootState) =>
    FormsSelector.getFormValues(state, formId),
  )
  const loginError = useSelector(AuthSelector.error)

  const from = state?.from || '/home'

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    if (loginError) {
      resetPasswordField()
      dispatch(SnackbarActions.showSnackbar({ message: loginError }))
    }

    return () => {
      dispatch(AuthActions.resetError())
    }
  }, [loginError])

  useEffect(() => {
    const provider = localStorage.getItem('authProvider')

    if (provider) {
      switch (provider) {
        case 'google':
          authCode && getGoogleAccessToken()
          socialAccessToken && getJwtWithGoogle(socialAccessToken)
          break
        case 'linkedin':
          authCode && getLinkedInAccessToken()
          socialAccessToken && getJwtWithLinkedin(socialAccessToken)
          break
        case 'microsoft':
          authCode && getMicrosoftAccessToken()
          socialAccessToken && getJwtWithMicrosoft(socialAccessToken)
          break
        default:
          dispatch(SnackbarActions.showSnackbar({ message: 'invalid auth provider' }))
          break
      }
    }
  }, [authCode, socialAccessToken])

  const getGoogleAccessToken = async () => {
    try {
      setLoading(true)
      const actionResult = await dispatch(AuthActions.googleAuthAsync({ authCode }))

      if (AuthActions.googleAuthAsync.fulfilled.match(actionResult)) {
        const accessToken = actionResult.payload.access_token

        await getJwtWithGoogle(accessToken)
      }
    } catch (error: any) {
      dispatch(SnackbarActions.showSnackbar({ message: error.message }))
    } finally {
      setLoading(false)
    }
  }

  const getJwtWithGoogle = async (token: string) => {
    try {
      setLoading(true)
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      const loginAction = await dispatch(AuthActions.googleLoginAsync(options))

      if (AuthActions.googleLoginAsync.fulfilled.match(loginAction)) {
        navigate(from, { replace: true })
      }
    } catch (error) {
      return Promise.reject(error)
    } finally {
      setLoading(false)
    }
  }

  const getLinkedInAccessToken = async () => {
    try {
      setLoading(true)
      const actionResult = await dispatch(AuthActions.linkedInAuthAsync({ authCode }))

      if (AuthActions.linkedInAuthAsync.fulfilled.match(actionResult)) {
        const accessToken = actionResult.payload.access_token

        await getJwtWithLinkedin(accessToken)
      }
    } catch (error: any) {
      dispatch(SnackbarActions.showSnackbar({ message: error.message }))
    } finally {
      setLoading(false)
    }
  }

  const getJwtWithLinkedin = async (token: string) => {
    try {
      setLoading(true)
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      const loginAction = await dispatch(AuthActions.linkedinLoginAsync(options))

      if (AuthActions.linkedinLoginAsync.fulfilled.match(loginAction)) {
        navigate(from, { replace: true })
      }
    } catch (error) {
      return Promise.reject(error)
    } finally {
      setLoading(false)
    }
  }

  const getMicrosoftAccessToken = async () => {
    try {
      setLoading(true)
      const actionResult = await dispatch(AuthActions.microsoftAuthAsync({ authCode }))

      if (AuthActions.microsoftAuthAsync.fulfilled.match(actionResult)) {
        const accessToken = actionResult.payload.access_token

        await getJwtWithMicrosoft(accessToken)
      }
    } catch (error: any) {
      dispatch(SnackbarActions.showSnackbar({ message: error.message }))
    } finally {
      setLoading(false)
    }
  }

  const getJwtWithMicrosoft = async (token: string) => {
    try {
      setLoading(true)
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      const loginAction = await dispatch(AuthActions.microsoftLoginAsync(options))

      if (AuthActions.microsoftLoginAsync.fulfilled.match(loginAction)) {
        navigate(from, { replace: true })
      }
    } catch (error) {
      return Promise.reject(error)
    } finally {
      setLoading(false)
    }
  }

  const resetPasswordField = () => {
    setTouched({ password: false })
    setFieldValue('password', '')
  }

  const formik = useFormik({
    initialValues: {
      email: email as string,
      password: '',
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: async ({ email, password }, _) => {
      try {
        const resultAction = await dispatch(AuthActions.userLoginAsync({ email, password }))

        if (AuthActions.userLoginAsync.fulfilled.match(resultAction)) {
          navigate(from, { replace: true })
        }
      } catch (error: any) {
        dispatch(SnackbarActions.showSnackbar({ message: error.message }))
      }
    },
  })

  const { errors, touched, handleSubmit, getFieldProps, setTouched, setFieldValue } = formik

  const url =
    (window.env && window.env.API_URL !== '__API_URL__' && window.env.API_URL) ||
    process.env.REACT_APP_API_URL

  const handleGoogleLogin = () => {
    localStorage.setItem('authProvider', 'google')
    window.location.href = `${url}/v1/auth/google`
  }

  const handleMicrosoftLogin = () => {
    localStorage.setItem('authProvider', 'microsoft')
    window.location.href = `${url}/v1/auth/microsoft`
  }

  const handleLinkedinLogin = () => {
    localStorage.setItem('authProvider', 'linkedin')
    window.location.href = `${url}/v1/auth/linkedin`
  }

  const handleForgotPasswordClick = () => {
    navigate('/forgotpassword')
  }

  return (
    <Stack alignItems="center" width="100%" padding={{ xs: '1rem', sm: '0.5rem' }}>
      {loading ? (
        <AuthLoader message=" Please be patient we are just letting you in" />
      ) : (
        <Card sx={{ padding: '2.5rem', maxWidth: { xs: '100%', sm: '480px' } }}>
          <FormikProvider value={formik}>
            <Typography
              variant="h5"
              fontWeight={theme.typography.fontWeightMedium}
              fontSize={{ xs: '1.1rem', sm: '1.5rem' }}
              mb="1.5rem"
            >
              Sign in to find the perfect grant
            </Typography>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={1}>
                <Box width="100%">
                  <TextField
                    type="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                    autoFocus
                    InputLabelProps={{ shrink: true }}
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={(touched.email && errors.email) || ' '}
                  />

                  <TextField
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    {...getFieldProps('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={(touched.password && errors.password) || ' '}
                  />

                  <Box display="flex" alignItems="center" justifyContent="right">
                    <Typography
                      color="primary.main"
                      fontSize={{ xs: '14px', sm: 'medium' }}
                      fontWeight={theme.typography.fontWeightMedium}
                      sx={{
                        cursor: 'pointer',
                        mb: 3,
                      }}
                      onClick={handleForgotPasswordClick}
                    >
                      Forgot Password?
                    </Typography>
                  </Box>
                </Box>
                <Box width="100%">
                  <Button
                    variant="contained"
                    type="submit"
                    disableElevation
                    fullWidth
                    sx={{
                      height: '50px',
                      fontSize: { xs: '0.857rem', sm: '1rem' },
                      textTransform: 'none',
                      fontWeight: 500,
                      mb: 2,
                    }}
                  >
                    Sign in
                  </Button>

                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontWeight: theme.typography.fontWeightBold,
                      mb: 2,
                    }}
                  >
                    Or
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="center" gap={1}>
                  <IconButton sx={iconStyle} aria-label="Google" onClick={handleGoogleLogin}>
                    <GoogleIcon />
                  </IconButton>
                  <IconButton sx={iconStyle} aria-label="Microsoft" onClick={handleMicrosoftLogin}>
                    <MicrosoftIcon />
                  </IconButton>
                  <IconButton sx={iconStyle} aria-label="LinkedIn" onClick={handleLinkedinLogin}>
                    <LinkedInIcon />
                  </IconButton>
                </Box>

                <Box textAlign="center" py={2}>
                  <Typography
                    component="span"
                    fontSize="14px"
                    fontWeight={theme.typography.fontWeightMedium}
                    color="secondary.light"
                  >
                    No account?
                  </Typography>
                  <Button
                    variant="text"
                    sx={{
                      textTransform: 'none',
                      fontSize: { xs: '14px', sm: '0.9375rem' },
                      fontWeight: 'bold',
                    }}
                    onClick={() => navigate('/register')}
                  >
                    Create account
                  </Button>
                </Box>
              </Stack>
            </Form>
          </FormikProvider>
        </Card>
      )}
    </Stack>
  )
}
